import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { AuthConsumer } from './context/AuthContext'

const ProtectedRoute = ({ component: Component, superUser, ...rest }) => (
  <AuthConsumer>
    {({ token, data }) => {
      const canAccess = superUser ? data["is_superUser"] : true;
      return (
        <Route
          render={props =>
            token && canAccess ? <Component {...props} /> : <Redirect to="/login" />
          }
          {...rest}
        />
      )
    }}
  </AuthConsumer>
)

export default ProtectedRoute