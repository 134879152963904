// export const HOST_PREFIX = 'https://';
// export const HOST_SUFFIX = 'b5tqu8slwa.execute-api.us-east-1.amazonaws.com/staging/';

export const APP_ID = '0eaf103e569f7883d401a44faed23f6733fa0d115861db079c6521071b5db517'; // akcelita1
export const HOST_PREFIX = process.env.REACT_APP_HOST_PREFIX || 'http://';
export const HOST_SUFFIX = process.env.REACT_APP_HOST_SUFFIX || 'localhost:4000/';
export const DEV_MODE = !!process.env.REACT_APP_DEV_MODE;
export const SHELFOS_BASE_URL = process.env.SHELFOS_BASE_URL || 'https://shelfos.artificialsensing.com/';

if (DEV_MODE){
    console.log("DEV_MODE");
}

/* All supported country codes can be seen with momentTz.tz.countries() */
export const SUPPORTED_COUNTRIES_TIMEZONES = ['US', 'PR', 'PA'];
export const DEFAULT_LANGUAGE_CODE = 'en-us';
export const MOMENT_DATE_TIME_FORMAT_TZ = 'YYYY-MM-DD HH:mm z';
