import { useState, useEffect } from 'react';

/** Hook for loading a resource.
 * Loads a resource as an effect whenever the dependencies change.
 * 
 * @param {Function} resourceFn - async function returning the resource
 * @param {Array} dependencies - List of dependencies. The function is called whenever an item changes in this list.
 * @param {Object} initialValue - Initial resource value
 *
 * @returns {Object} the requested resource data.
 * @returns {Boolean} flag indicating that the resource is loading.
 * @returns {Boolean} flag indicating that an error ocurred while loading the resource.
 * 
 */
function useResourceLoader(resourceFn, dependencies, initialValue){
    const [resource, setResource] = useState(initialValue);
    const [loading, setLoading] = useState();
    const [error, setError] = useState();

    const resourceLoader = () => loadResource(resourceFn, {
        setResource(newResource) {
            if(newResource !== useResourceLoader.DoNotSet && newResource !== resource) {
                setResource(newResource);
            }
        },
        setLoading, setError
    });

    useEffect(() => { resourceLoader(); }, dependencies);
    
    return [resource, loading, error, resourceLoader, setResource];
}

useResourceLoader.DoNotSet = {};


/** Loads a resource, activating a loader while loading and setting error if necesary.
 * 
 * @param {Function} resourceFn - async function returning the resource
 * @param {Object} options
 * @param {Function} options.setResource - hook function used to set the loaded resource.
 * @param {Function} options.setLoading - hook function used to set the loading state.
 * @param {Function} options.setError - hook function used to set the error state.
 *                              If not set, any catched errors will be rethrown.
 * 
 * @returns {Promise} resolving to the loaded resource, or rejecting on error.
 *          If setError is given it will be called on any error, the error will not be rethrown
 *          and the promise will resolve to undefined.
 */
export function loadResource(resourceFn, { setResource, setLoading, setError }){

    if (setLoading) { setLoading(true); }

    return Promise.resolve().then(
        resourceFn
    ).then((newResource) => {
        if (setResource) { setResource(newResource); }
        if (setLoading) { setLoading(); }
        if (setError) { setError(); }

        return newResource;
    }).catch(err => {
        if (setLoading) { setLoading(); }

        if (setError) {
            setError(err);
        } else {
            throw err;
        }
    });
}


export default useResourceLoader;
