import React, { useMemo } from 'react'


export const ResponsiveContext = React.createContext();


const MOBILE_BREAKPOINT = 680;


export function ResponsiveProvider({ children }) {

    const value = useMemo(() => {
        const windowClientRect = document.body.getBoundingClientRect();

        return {
            isMobile: windowClientRect.width <= MOBILE_BREAKPOINT,
            windowClientRect
        }

    }, []);

    return (
        <ResponsiveContext.Provider value={value}>
        {children}
        </ResponsiveContext.Provider>
    )
}


export const ResponsiveConsumer = ResponsiveContext.Consumer
